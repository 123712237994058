$(async function (){

    const bind = (item) => {
        const price = $(item).data('price');
        const $percentInput = $(item).find('[data-percent-input]');
        const percent = $percentInput.val();
        const $holder = $(item).find('[data-price-ones]');
        const $formLink = $(item).find('[data-request-form-link]');
        let formData = $(item).find('form').serialize();
        const once = Math.round(price * percent / 100);
        $holder.html(new Intl.NumberFormat('de-DE').format(once));
        $percentInput.on('change', function(){
            const once = Math.round(price * $(this).val() / 100);
            $holder.html(new Intl.NumberFormat('de-DE').format(once));
        });

        $formLink.on('click', function (e){
            e.preventDefault();
            const $link = $(this);
            formData = $(item).find('form').serialize();
            const href = $link.attr('href').split('?')[0] + '?' + formData;
            $link.attr('href', href);
            $link.modal({
                closerText   : '',
                closeOnClickBg: false,
                animation    : {
                    classOut  : 'animation-out',
                    timeoutOut: 600,
                },
                onAfterOpen: function () {
                    if ($link.data('goal')) {
                        window.goal($link.data('goal'));
                    }
                },
                onFormSuccess: function () {
                    if ($link.data('goal-success')) {
                        window.goal($link.data('goal-success'));
                    }
                }
            });
            return false;
        });

    };
    $.each($('[data-yach-owner]'), (index, item) => {
        bind(item);
    });
});
