export default class FilterCatalog {
    constructor (form) {
        this.form = form;
        this.init();
        this.handlers = {};
    }
    init() {
        if (!this.form) return;
        this.form.addEventListener('change',(e)=>{

            if(e.target.type == 'checkbox') {
                this.form.submit();
                return;
            }
            if(e.target.type !== 'radio') return;
            if(e.target.hasAttribute('data-order')) {
                this.form.submit();
                return;
            }
            this.checkCategory(e.target.value)
        })
        this.form.addEventListener('reset',(e)=>{
            e.preventDefault();
            this.emit('reset');
        })
        // this.checkCategory(false);
    }

    checkElementExclude(element, type) {
        if(!element.getAttribute('data-exclude')) return;
        let excludeString = element.getAttribute('data-exclude').split(",");
        let status = excludeString.includes(type);
        return status;
    }

    checkCategory(type) {
        this.form.reset();
        setTimeout(() => this.form.submit(), 0)
        return;

        Array.from(this.form.elements).forEach(element => {
            if(element.type == 'radio' && element.checked && !type) {
                type = element.value;
            }
            this.changeView(element, this.checkElementExclude(element, type));
        });
    }
    changeView(element, status) {
        let view = status ? 'none' : 'flex';
        let block = element.closest('.filter-form__element');
        if(block) {
            block.style.display = view;
        }
    }

    on(eventName, handler) {

        if (eventName in this.handlers) {
            this.handlers[eventName].push(handler);
        } else {
            this.handlers[eventName] = [ handler ];
        }
    };

    emit(eventName, args) {
        if (eventName in this.handlers) {
            for (var handler of this.handlers[eventName]) {
                setTimeout(() => { handler(); }, 0);
            }
        }
    }
}
