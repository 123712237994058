$(document).ready(function () {


    let lazyloadImages = $(".lazy");
    let lazyloadImagesBg = $(".lazy-bg");
    const countToLoad = lazyloadImagesBg.length + lazyloadImages.length;
    let loadedBgImages = 0;

    function lazyload() {
        lazyloadImages.each(function () {
            const el = $(this);
            const url = el.attr("data-src");
            el.attr("src", url);
            el.removeClass("lazy");
            loadImage(el, url);

            lazyloadImages = $(".lazy");
        });

    }

    function lazyloadbg() {

        lazyloadImagesBg.each(function () {
            const el = $(this);
            if (el.data('style')) {
                el.attr('style', el.data('style'))
                loadImage(el);
            }
            el.removeClass("lazy-bg");
            lazyloadImagesBg = $(".lazy-bg");
        });
    }


    function loadImage(element, srcUrl = false) {
        const img = new Image();

        if (!srcUrl) {
            const style = element.currentStyle || window.getComputedStyle(element.get(0), false);
            img.src = style.backgroundImage.slice(4, -1).replace(/"/g, "");
        } else {
            img.src = srcUrl
        }

        img.onload = function () {
            loadedBgImages++;
            onImageLoad();

        };
        img.error = function () {
            loadedBgImages++;
            onImageLoad();
        }
    }

    function onImageLoad() {
        if (loadedBgImages == countToLoad) {
            console.log('trigger load');

            $(window).trigger('images_loaded');
        }
    }


    let $smallBg = $('[data-small-bg]');


    function replaceSrcImages() {
        const $windowWidth = $(window).width();
        $.each($smallBg, function () {
            const $this = $(this);
            let bg = $this.data('original');
            if ($windowWidth < 700) {
                bg = $this.data('small-bg');
            }
            $this.attr('style', "background-image: url('" + bg + "')");
        });
    }

    replaceSrcImages();
    lazyloadbg();
    lazyload();


})
