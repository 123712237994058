window.addEventListener('DOMContentLoaded', () => {
    const $tabs = $('.tab-product-content').length ? $('.tab-product-content') : false;
    const $tabsLinks = $('.tabs-list .tab-item:not([data-tour-link])').length ? $('.tabs-list .tab-item:not([data-tour-link])') : false;
    const $tourTab = $('[data-tour-link]').length ? $('[data-tour-link]') : false;
    const $tabsDecrs = $('.tab-product-descr').length ? $('.tab-product-descr') : false;
    const $tabsDecrsLink = $('.tabs-list .tab-item-descr').length ? $('.tabs-list .tab-item-descr') : false;

    function changeActiveTab (e, _this, tabs, link) {
        const $this = $(_this);
        const $container = $('#' + $this.data('tab'));

        e.preventDefault();

        if ($this.hasClass('active')) {
            return false;
        }

        tabs.removeClass('active');

        link.removeClass('active');

        $container.addClass('active');
        $this.addClass('active');

        return false;
    }

    const $mainSlider = $('[data-slider-with-nav-main]').length ? $('[data-slider-with-nav-main]') : false;
    const $dataSliderNav = $('[data-slider-with-nav-nav]').length ? $('[data-slider-with-nav-nav]') : false;


    if ($mainSlider) {
        $mainSlider.each(function(key, el) {

            $(el).slick({
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: false,
                fade: true,
                lazyLoad: 'ondemand',
                asNavFor: $dataSliderNav[key],
                autoplay: true,
            })
        })

        $dataSliderNav.each(function(key, el) {
            $(el).slick({
                slidesToShow: 5,
                slidesToScroll: 1,
                asNavFor: $mainSlider[key],
                focusOnSelect: true,
                infinite: false,
                arrows: false,
                lazyLoad: 'ondemand',
                responsive: [
                    {
                        breakpoint: 1007,
                        settings: {
                            vertical: false,
                            slidesToShow: 3,
                        }
                    }
                ]
            });

        })

        let slideCount = $mainSlider.slick("getSlick").slideCount;
        $('<div>', {class: 'slick-counter'}).appendTo($mainSlider);

        $('<span>', {
            class: 'now-slide',
            text: '1/' + slideCount
        }).appendTo($mainSlider.children(".slick-counter"));


        $mainSlider.on('beforeChange', function (event, slick, currentSlide, nextSlide) {
            $mainSlider.children(".slick-counter").children('.now-slide').html((nextSlide + 1) + "/" + slideCount);
        });

    }

    if($tabsLinks) {
        $tabsLinks.on('click', function(e){
            changeActiveTab(e, this, $tabs,  $tabsLinks)
        });
    }
    if($tabsDecrsLink) {
        $tabsDecrsLink.on('click', function(e){
            changeActiveTab(e, this, $tabsDecrs,  $tabsDecrsLink)
        });
    }
    if($tourTab) {
        $tourTab.on('click', function (e) {
           e.preventDefault();
           $('.tour-mobile-wrapper').toggleClass('open');
           return false;
        });
    }
    const $appearancePreview = $('#appearance .preview').length ? $('#appearance .preview') : false
    if($appearancePreview) {
        $appearancePreview.on('click', function () {
            const $this = $(this);

            $('.preview').removeClass('active');
            $this.addClass('active');
            $('[data-scheme-image]').removeClass('active');
            $($this.attr('href')).addClass('active');
            return false;
        });
    }
})
