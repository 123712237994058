// основной код
import SwiperCore, {Swiper, Controller} from 'swiper/core';
SwiperCore.use([Swiper, Controller]);
// основные стили
import 'swiper/swiper.min.css';

export function yachtModelSlider() {
    const yachtSliders = document.querySelectorAll('.swiper-container.swiper-container_yacht');
    const yachtButtonsCase = document.querySelectorAll('.yacht-model__model-case');
    const yachtSlidersMobile = document.querySelectorAll('.swiper-container_yacht-mobile');
    let counter = 0;

    if (yachtSliders.length && yachtSlidersMobile.length) {
        yachtSliders.forEach((el, key) => {
            if(getSlidesQuantity(el) > 1) {
                if (getSlidesQuantity(el) == 2) {
                    newSwiper(el, key, 'two-slides-')
                } else {
                    newSwiper(el, key, 'more-slides-')
                }
            }
        })
    }

    function newSwiper(el, key, name) {

        if(key) {
            let swiper = new Swiper(el, {
                speed: 700,
                loop: true,
                slidesPerView: 1,
                containerModifierClass: name,
                slideActiveClass: 'swiper-real-active-slide',
                breakpoints: {
                    1024: {
                        width: 1290,
                        loopedSlides: 3,
                        centeredSlides: true,
                        slideToClickedSlide: true,
                        allowTouchMove: false,
                    },
                },
            })

            let swiperMobile = new Swiper(yachtSlidersMobile[key], {
                speed: 700,
                loop: true,
                slidesPerView: 1,
                breakpoints: {
                    1024: {
                        width: 1290,
                        loopedSlides: 3,
                        centeredSlides: true,
                        slideToClickedSlide: true,
                        allowTouchMove: false,
                    },
                },
            })
            swiper.controller.control = swiperMobile
            swiperMobile.controller.control = swiper

            buttonSlug(swiper)
        }
    }
    function buttonSlug(swiper) {
        let btns = getButtonCase(counter).querySelectorAll('.yacht-model__model-name')

        add(btns, getActiveSlide(swiper))

        swiper.on('slideChange', function () {
            btns.forEach((btn, index) => {
                remove(btns, index)
                add(btns, getActiveSlide(swiper))
            })

            swiper.update()
        });

            btns.forEach((btn, index) => {
                btn.addEventListener('click', () => {
                    let dataIndex = btn.dataset.index
                    btns.forEach((el) => {
                            el.classList.remove('yacht-model__model-name_active')
                    })
                    add(btns, dataIndex)
                    swiper.slideToLoop(+dataIndex)
                    swiper.update()
                })
            })
        counter++
    }
    function add(elem, i) {
        elem[i].classList.add('yacht-model__model-name_active')
    }
    function remove(elem, i) {
        elem[i].classList.remove('yacht-model__model-name_active')
    }
    function getActiveSlide(slider) {
        return slider.realIndex
    }
    function getSlidesQuantity(slider) {
        return slider.querySelectorAll('.swiper-slide').length
   }
   function getButtonCase(counter) {
    return yachtButtonsCase[counter]
   }
}
