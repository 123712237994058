window.addEventListener('DOMContentLoaded', loadOwl)

function loadOwl() {
    const owl = $('[data-adv-owl]');

    if(owl.length) {
        owlOptions = {
            center: true,
            items:1,
            loop:true,
            margin:0,
            dots: true,
            nav:true,
        };

    if ( $(window).width() < 860 ) {
        let owlActive = owl.owlCarousel(owlOptions);
    } else {
        owl.addClass('off');
    }

    $(window).resize(function() {
        if ( $(window).width() < 860 ) {
            if ( $('.owl-carousel').hasClass('off') ) {
                let owlActive = owl.owlCarousel(owlOptions);
                owl.removeClass('off');
            }
        } else {
            if ( !$('.owl-carousel').hasClass('off') ) {
                owl.addClass('off').trigger('destroy.owl.carousel');
                owl.find('.owl-stage-outer').children(':eq(0)').unwrap();
            }
        }
    });
    }
}
