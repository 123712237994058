$(function () {

    var pagerLinkSelector = '[data-next-pager-link]',
        strategy = 'append';


    $(document).on('click', pagerLinkSelector, function (e) {//link-addload]', function (e) {
            e.preventDefault();
            var $this = $(this),
                updateIdContainer = $this.data('next-pager-link'),
                updateSelector = '[data-pagination-update="'+updateIdContainer+'"]',
                paginationListSelector = '[data-pagination-list="'+updateIdContainer+'"]';

            if($this.hasClass('loading')){
                return false;
            }

            strategy = $(updateSelector).data('strategy') ? $(updateSelector).data('strategy') : strategy;

            $this.addClass('loading');
            $.ajax({
                url: $this.attr('href'),
                success: function (page) {
                    var $page = $('<div/>').append(page);
                    if(strategy == 'news'){
                        $(updateSelector).append($page.find('[data-item]'));
                        if ($page.find(paginationListSelector).length) {
                            $('.news-list').addClass('show-last-item');
                        }
                    }
                    if(strategy == 'exhibition'){
                        $(updateSelector).append($page.find(updateSelector).html());
                    }

                    $(paginationListSelector).html($page.find(paginationListSelector).html());
                    $this.removeClass('loading');
                }
            });
            return false;
        }
    );

    $('[data-show-pagination-item]').on('click', function () {
        $('.news-list').addClass('show-last-item');
        $(this).remove();
    });
});