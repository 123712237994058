$(function () {

    let setTransform = (checkOpen = true) => {
        let $rightContainer = $('[data-right-container]');

        if (checkOpen && !$rightContainer.hasClass('menu-opened')) {
            return false;
        }

        let $menu = $('[data-menu-container]');
        let width = $menu.width();
        let windowWidth = $(window).width();
        let headerWidth = $('header.top-header .row').width();
        let delta = 10;
        let transform = width + delta - ((windowWidth - headerWidth) / 2);
        $rightContainer.css({
            'transform': 'translateX(-' + transform + 'px)'
        })
    };

    $(document).on('click', '[data-toggle-menu]', function (e) {
        e.preventDefault();
        let $menu = $('[data-menu-container]');
        let $linksToggle = $('[data-toggle-menu]');
        let $rightContainer = $('[data-right-container]');


        $menu.toggleClass('opened');
        $linksToggle.toggleClass('opened');
        $('body').toggleClass('menu-open');

        if ($rightContainer.hasClass('menu-opened')) {
            $rightContainer.removeAttr('style')
        } else {
            setTransform(false);
        }
        $rightContainer.toggleClass('menu-opened');

        return false;
    });

    $(window).resize(function () {
        setTransform();
    });

    const dropperMenu = '[data-mobile-dropper="level-1"]';
    const dropperMenulvl2 = '[data-mobile-dropper="level-2"]';

    if (respond('small') || respond('smalled') || respond('medium') ) {
        $(dropperMenu).on('click', function (e) {
            e.preventDefault();
            const  $current =   $(this).closest('li');
            const $another = $current.closest('.menu-list').find('.root-menu');
            if($current.hasClass('active')){
                $current.toggleClass('active');
            }else{
                $another.removeClass('active');
                $current.toggleClass('active');
            }

            return false;
        });
        $(dropperMenulvl2).on('click', function (e) {
            e.preventDefault();
            const  $current =   $(this).closest('li');
            const $another = $(dropperMenulvl2).closest('li');
            if($current.hasClass('active')){
                $current.toggleClass('active');
            }else{
                $another.removeClass('active');
                $current.toggleClass('active');
            }
            return false;
        });


    }
});
