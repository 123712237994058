require('../scss/app.scss');
require('../fonts/Oswald/font.css');
require('../fonts/SegoeUI/font.css');
require('./parts/form-animation.js');

require('@fancyapps/fancybox');
require('@fancyapps/fancybox/dist/jquery.fancybox.css');


require('../components/slide-page/slide-page.js');
require('../components/slide-page/slide-page.css');

require('../components/sticky/sticky.js');

require('slick-carousel/slick/slick.js');
require('slick-carousel/slick/slick.css');

require('jquery.cookie/jquery.cookie.js');
require('./owl.js');

require('./common/goal.js');
require('./common/polipop.js');
require('./common/js_validation.js');
require('./common/links.js');
require('./common/respond.js');
require('./common/mobile-menu.js');
require('./common/paginator_news.js');


require('./common/map/google-maps.js');
require('./common/preloader.js');
require('./common/youtube.js');


require('./common/main.js');
require('./common/smooth-scroll-to-anchors.js');


require('./main.js');
require('./parts/lazy.js');
require('./parts/product.js');
require('./parts/contacts.js');
require('./leasing.js');
require('./ownership.js');
require('readmore-js');
require('select2/dist/css/select2.css');
require('select2/dist/js/select2.js');
require('select2/dist/js/i18n/ru.js');
require('jquery-youtube-background/src/jquery.youtubebackground');

import 'owl.carousel';

require('jquery.maskedinput/src/jquery.maskedinput.js');
import AOS from 'aos';
import 'aos/dist/aos.css';



setTimeout(() => {
    document.dispatchEvent(new Event('run_animation'));
}, 500);


AOS.init({
    // Global settings:
    startEvent: 'run_animation', // name of the event dispatched on the document, that AOS should initialize on
});
