$(() => {
    const formGetUrl = ['get_tickets','get_tickets_vip'];

    let $images = $('img');
    let $bgs = $('div').filter(function () {
        return $(this).css("background-image") !== 'none';
    });
    let $src = [];
    let $loader = $('[data-progress-line]');
    let $loaderWrapper = $('.main-preloader');

    function removePreloader() {
        $loaderWrapper.addClass('animated');
        $(document).trigger('loader-loaded');
    }
    
    if (!!(formGetUrl.indexOf(window.location.search.substr(1))+1)) {
        removePreloader();
    }

    $.each($images, function () {
        $src.push($(this).attr('src'));
    });


    $.each($bgs, function () {
        let matched = /^url\((['"]?)(.*)\1\)$/.exec($(this).css('background-image'));
        let url = matched ? matched[2] : false;
        if (url) {
            $src.push(url);
        }
    });

    $src = $src.slice(1);
    let percentage = 100 / $src.length;
    let width = 0;

    function addWidth() {
        width += percentage;
        $loader.width(width + '%');

        if(maxTime === execTime){
            console.log('loader end');
            width = 100;
            clearInterval(interval);
            removePreloader();
        }
        if (width >= 99) {
            removePreloader();
        }
    }

    let execTime = 0;
    let maxTime = 2;

    let interval = setInterval(()=>{
        execTime++;
    }, 1000);

    $.each($src, function () {
        let $img = $('<img/>');

        $img.on('load', function () {
            addWidth();
        }).on('error', function () {
            addWidth();
        });

        $img.attr('src', this);
    });

    $(window).on('load', function () {
        removePreloader();
    });
});
