$(function () {
    if ($('#google-map').length > 0) {



        $.get('/contacts/json', (googleMapPoints) => {


            const googleMapCenter = {
                'lat': '55.768213',
                'lng': '37.594105',
            };

            window.googlemapinit = function (mapContainer, points, center) {
                require('./custom-marker.js');
                require('./info-window.js');

                const mapCenter = new google.maps.LatLng(center.lat, center.lng);
                const infoMarkers = [];
                const markers = [];

                const styles = [
                    {
                        "featureType": "administrative",
                        "elementType": "labels.text",
                        "stylers": [
                            {
                                "visibility": "on"
                            }
                        ]
                    },
                    {
                        "featureType": "poi",
                        "elementType": "all",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "featureType": "transit.station.rail",
                        "elementType": "all",
                        "stylers": [
                            {
                                "visibility": "simplified"
                            },
                            {
                                "saturation": "-100"
                            }
                        ]
                    },
                    {
                        "featureType": "water",
                        "elementType": "all",
                        "stylers": [
                            {
                                "visibility": "on"
                            }
                        ]
                    }
                ];

                const myOptions = {
                    zoom: 5,
                    center: mapCenter,
                    mapTypeId: google.maps.MapTypeId.ROADMAP,
                    styles: styles,
                    panControl: false,
                    zoomControl: true,
                    scaleControl: false,
                    streetViewControl: false,
                    scrollwheel: false,
                    mapTypeControl: false,
                    zoomControlOptions: {
                        position: google.maps.ControlPosition.RIGHT_BOTTOM
                    },
                };

                const map = new google.maps.Map(document.getElementById(mapContainer), myOptions);

                $('#' + mapContainer).data('google-map', map);

                $(document).on('click', '[data-closer-info-window]', function (e) {
                    e.preventDefault();
                    const $this = $(this);
                    $this.closest('.infobox').addClass('no-active');
                    return false;
                });

                $(document).on('click', '[data-toggle-placemark]', function (e) {
                    e.preventDefault();
                    $('[data-toggle-placemark]').removeClass('active');
                    $(this).addClass('active');

                    var data_placemark = $(this).data('toggle-placemark');
                    $("#change-region-map option").hide();
                    $("#change-region-map option[data-region-type='"+data_placemark+"']").show();
                    $("#change-region-map option[data-all]").show();
                    showHideMarkers();

                    return false;
                });

                const geoCoords = [];

                $(document).on('change', '#change-region-map', function (e) {
                    const location = $(this).val();
                    if (geoCoords.length && geoCoords[location]) {
                        map.panTo(geoCoords[location]);
                    } else {
                        $.get('https://nominatim.openstreetmap.org/search?format=json&q=' + location, function (data) {
                            if (data.length > 0) {
                                const first = data.shift();
                                if (first.hasOwnProperty('lat') && first.hasOwnProperty('lon')) {
                                    const lat = first.lat;
                                    const lon = first.lon;
                                    geoCoords[location] = new google.maps.LatLng(parseFloat(lat), parseFloat(lon));
                                    map.panTo(geoCoords[location]);
                                }
                            }
                        });

                    }
                    showHideMarkers();

                });

                function showHideMarkers() {
                    const $this = $('[data-toggle-placemark].active');
                    let type = $this.data('toggle-placemark') ? '.' + $this.data('toggle-placemark') : '';
                    const $mapContainer = $('#' + mapContainer);
                    const selectedRegion = $('#change-region-map').val();
                    if (selectedRegion) {
                        type += '.' + selectedRegion;
                    }

                    $mapContainer.find('.placemark-holder').hide();
                    $mapContainer.find('.placemark-holder' + type).show();

                    $mapContainer.find('.infobox').addClass('no-active');
                }

                const addMarkerWithTimeout = function (map, point, timeout) {

                    let position = new google.maps.LatLng(parseFloat(point['lat']), parseFloat(point['lng'])),
                        template = "" +
                            "<div id='info-window-" + point['id'] + "' class='map-point-info'>" +
                            "<div class='info-holder'>" +
                            "<span class='point-name'>" + point['name'] + "</span>" +
                            "<a href='#' data-closer-info-window='#info-window-" + point['id'] + "' class='closer-info'>&times;</a>" +
                            "<span class='point clearfix'><span class='label'>Адрес: </span><span class='address-value'>" + point['address'] + "</span></span>";

                    if (point['phone']) {
                        point['phone'] = point['phone'].trim().split(/\n/);
                        point['phone'].forEach(function(element) {
                            template += "<span class='point clearfix'><span class='label'>Телефон:</span><a href='tel:" + element + "' class='point-phone'>" + element + "</a></span>";
                        });
                    }

                    if (point['email']) {
                        template += "<span class='point clearfix'><span class='label'>E-mail:</span><a href='mailto:" + point['email'] + "'>" + point['email'] + "</a></span>";
                    }

                    template += "</div>" +
                        "</div>";


                    infoMarkers[point['id']] = new window.InfoBox({
                        latlng: position,
                        map: map,
                        content: template,
                        id: point.id,
                        cssClass: point.is_service ? 'service' : '',
                    });

                    const icon = point.is_service ? 'placemark-service' : 'placemark';
                    const region = point.region;
                    const hide = point.is_service ? 'style="display: none"' : '';

                    markers[point['id']] = new window.CustomMarker({
                        latlng: position,
                        id: point.id,
                        map: map,
                        content: '<span '+hide+' class="placemark-holder ' + icon + ' ' + region + '">' +
                            '<i class="svg-icon ' + icon + '"></i>' +
                            '</span>',
                        cssClass: 'animation-delay-' + timeout
                    });


                    google.maps.event.addListener(markers[point['id']], 'click', function (event) {
                        openInfoWindow(markers[point['id']]);
                    });

                    const openInfoWindow = function (marker) {
                        let marker_icon = marker.div_,
                            id = marker.id,
                            $infoWindow = $('#info-window-' + id).closest('.infobox');


                        $(".infobox").addClass('no-active');

                        $infoWindow.removeClass('no-active');

                        $('#' + id).toggleClass('show');

                        $(marker_icon).addClass('open');

                        if ( $(window).width() > 1007 ) {
                            offsetCenter(marker.latlng_, 0, -100);
                        } else if ($(window).width() > 849) {
                            offsetCenter(marker.latlng_, 0, 100);
                        } else if ($(window).width() > 629) {
                            offsetCenter(marker.latlng_, -100, 0);
                        } else if ($(window).width() > 320) {
                            offsetCenter(marker.latlng_, -230, 0);
                        }
                    };
                };

                function offsetCenter(latlng, offsetx, offsety) {


                    const scale = Math.pow(2, map.getZoom());

                    const worldCoordinateCenter = map.getProjection().fromLatLngToPoint(latlng);
                    const pixelOffset = new google.maps.Point((offsetx / scale) || 0, (offsety / scale) || 0);

                    const worldCoordinateNewCenter = new google.maps.Point(
                        worldCoordinateCenter.x - pixelOffset.x,
                        worldCoordinateCenter.y + pixelOffset.y
                    );

                    const newCenter = map.getProjection().fromPointToLatLng(worldCoordinateNewCenter);

                    map.panTo(newCenter);

                }


                google.maps.event.addListenerOnce(map, 'idle', function () {
                    $.each(points, function (index) {
                        addMarkerWithTimeout(map, this, index * 200);
                    });
                });
            };

            google.maps.event.addDomListener(window, 'load', googlemapinit(
                'google-map',
                googleMapPoints,
                googleMapCenter
            ));
        });
    }

});



