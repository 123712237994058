require('../../components/modal/modal.js');

$(document).on('click', '[data-modal]', function openModal(e) {
    e.preventDefault();
    let $link = $(this);
    let closeOnBgClick = true;
    if (this.dataset.modal === 'dont-close-on-bg-click') {
        let closeOnBgClick = false;
    }
    $link.modal({
        closerText   : '',
        closeOnClickBg: closeOnBgClick,
        animation    : {
            classOut  : 'animation-out',
            timeoutOut: 600,
        },
        onAfterOpen: function () {
            if ($link.data('goal')) {
                window.goal($link.data('goal'));
            }
        },
        onFormSuccess: function () {
            console.log($link.data('goal-success'));
            if ($link.data('goal-success')) {
                window.goal($link.data('goal-success'));
            }
        }
    });
    return false;
});

$(document).on('click', '[data-modal-small]', function openModal(e) {
    e.preventDefault();
    let $link = $(this);
    $link.modal({
        closerText   : '',
        animation    : {
            classOut  : 'animation-out',
            timeoutOut: 400
        },
        onFormSuccess: function () {
            if ($link.data('goal')) {
                window.goal($link.data('goal'));
            }
        }
    });
    return false;
});

$(document).on('click', '[data-open-child]', function (e) {
    e.preventDefault();
    $(this).closest('.item').find('.sub').toggleClass('open');
    $(this).toggleClass('open');
    return false;
});

$(function () {
    $('[data-big-slider]').slick({
        slidesToShow  : 1,
        slidesToScroll: 1,
        fade          : true,
        asNavFor      : '[data-small-slider]',
        responsive    : [
            {
                breakpoint: 700,
                settings  : {
                    arrows: false,
                    dots  : true,
                }
            },
            {
                breakpoint: 0,
            }
        ]
    });

    $('[data-small-slider]').slick({
        slidesToShow  : 7,
        slidesToScroll: 1,
        asNavFor      : '[data-big-slider]',
        dots          : false,
        centerMode    : false,
        focusOnSelect : true,
        arrows        : false,
    });

    $('.fancybox').fancybox();
});
