if (typeof google === 'object' && typeof google.maps === 'object') {

    function InfoBox(opts) {
        google.maps.OverlayView.call(this);
        this.latlng_ = opts.latlng;
        this.map_ = opts.map;
        this.content = opts.content;
        this.cssClass = opts.cssClass ? opts.cssClass : '';

        this.height_ = 165;
        this.width_ = 280;

        this.offsetVertical_ = opts.offsetVertical_ ? opts.offsetVertical_ : 0;
        this.offsetHorizontal_ = opts.offsetHorizontal_ ? opts.offsetHorizontal_ : 0;

        this.id = opts.id;
        this.marker_icon = opts.marker_icon;

        this.setMap(this.map_);
    }

    /* InfoBox extends GOverlay class from the Google Maps API
     */
    InfoBox.prototype = new google.maps.OverlayView();
    /* Creates the DIV representing this InfoBox
     */
    InfoBox.prototype.remove = function () {
        if (this.div_) {
            this.div_.parentNode.removeChild(this.div_);
            this.div_ = null;
        }
    };

    /* Redraw the Bar based on the current projection and zoom level
     */
    InfoBox.prototype.draw = function () {
        // Creates the element if it doesn't exist already.
        this.createElement();
        if (!this.div_) return;
        // Calculate the DIV coordinates of two opposite corners of our bounds to
        // get the size and position of our Bar
        const pixPosition = this.getProjection().fromLatLngToDivPixel(this.latlng_);
        if (!pixPosition) return;
        // Now position our DIV based on the DIV coordinates of our bounds
        //this.div_.style.width = this.width_ + "px";
        this.div_.style.left = (pixPosition.x + this.offsetHorizontal_) + "px";
        //this.div_.style.height = this.height_ + "px";
        this.div_.style.top = (pixPosition.y + this.offsetVertical_) + "px";
        this.div_.style.display = 'block';

    };
    /* Creates the DIV representing this InfoBox in the floatPane. If the panes
     * object, retrieved by calling getPanes, is null, remove the element from the
     * DOM. If the div exists, but its parent is not the floatPane, move the div
     * to the new pane.
     * Called from within draw. Alternatively, this can be called specifically on
     * a panes_changed event.
     */
    InfoBox.prototype.createElement = function () {
        const panes = this.getPanes();
        let div = this.div_;
        if (!div) {
            // This does not handle changing panes. You can set the map to be null and
            // then reset the map to move the div.
            div = this.div_ = document.createElement("div");
            div.className = "infobox no-active " + this.cssClass;
            const contentDiv = document.createElement("div");
            contentDiv.className = "content";
            contentDiv.innerHTML = this.content;
            div.appendChild(contentDiv);
            div.style.display = 'none';

            panes.floatPane.style['zIndex'] = 10000;

            panes.floatPane.appendChild(div);

        } else if (div.parentNode != panes.floatPane) {
            // The panes have changed. Move the div.
            div.parentNode.removeChild(div);
            panes.floatPane.style['zIndex'] = 10000;

            panes.floatPane.appendChild(div);

        } else {
            // The panes have not changed, so no need to create or move the div.
        }
        google.maps.event.addDomListener(this.div_, 'mousedown', this.stopPropagation_);
        google.maps.event.addDomListener(this.div_, 'dblclick', this.stopPropagation_);
        google.maps.event.addDomListener(this.div_, 'DOMMouseScroll', this.stopPropagation_);

        google.maps.event.trigger(this, 'ready');
    }

    InfoBox.prototype.stopPropagation_ = function (e) {
        if (navigator.userAgent.toLowerCase().indexOf('msie') != -1 && document.all) {
            window.event.cancelBubble = true;
            window.event.returnValue = false;
        } else {
            // e.preventDefault(); // optionally prevent default actions
            e.stopPropagation();
        }
    }

    InfoBox.prototype.close = function () {
        if (this.div_) {
            this.div_.className = "infobox no-active";
        }
    };

    InfoBox.prototype.open = function (callback) {
        const div = this.div_;
        if (!div) {
            return false
        } else {
            div.className = "infobox";

            if (callback instanceof Function) {
                callback.call(this);
            }
        }
    }

    window.InfoBox = InfoBox;
}





