window.goal = function (goal) {
     console.log(window.Ya);
    if (window.Ya && window.Ya._metrika) {
        window.Ya._metrika.counter.reachGoal(goal);
        window.Ya._metrika.counter.reachGoal('CONVERSION');
    }

    ga('create', 'UA-127505931-1', 'auto');

    ga('send', {
        hitType: 'event',
        eventAction: goal,
        eventCategory: goal,
        eventLabel: goal
    });
};
