window.noZensmooth = true;
import zenscroll from 'zenscroll';

let smoothScroll = document.querySelectorAll('[data-scroll]');
smoothScroll.forEach(function (element) {
    element.addEventListener('click', function (e) {
        e.preventDefault();
        zenscroll.to(this.hash, 200);
        return false;
    });
});