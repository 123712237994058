import 'air-datepicker/dist/air-datepicker.js';
import 'air-datepicker/dist/air-datepicker.css';

import 'lazyload';
import noUiSlider from 'nouislider';
import wNumb from 'wnumb';
import FilterCatalog from './filter-catalog';
import { yachtModelSlider } from './parts/model';

window.noUiSlider = noUiSlider;
window.FilterCatalog = FilterCatalog;
window.wNumb = wNumb;

window.addEventListener('DOMContentLoaded', afterDOMContentLoaded)

function afterDOMContentLoaded() {
    menuOpener()
    mainSliderInit()
    lazyload();
    playerLoader()
    // yachtModelSlider()
}

function playerLoader() {
    const players = document.querySelectorAll('.top-block__video');
    players.forEach(player => initplayer(player))

    let id, video
    function initplayer(player) {
        id = player.getAttribute('id')

        video = $('#'+id);
        video.YTPlayer({
            videoId: id,
            containment: 'self',
            showControls: false,
            autoPlay: true,
            loop: true,
            vol: 0,
            mute: true,
            startAt: 0,
            stopAt: 0,
            opacity: 1,
            addRaster: true,
            quality: 'default',
            optimizeDisplay: true
        });
    }
}

function menuOpener() {
    const menuBody = document.querySelector('[data-menu-container]')
    const menuBtn = menuBody.nextElementSibling.querySelector('[data-mobile-menu-opener]')

    menuBtn.addEventListener('click', () => {
       if(menuBtn.checked) {
           menuBody.classList.add('opened')
           menuBtn.classList.add('opened')
       } else {
           menuBody.classList.remove('opened')
           menuBtn.classList.remove('opened')
       }
    })
}

function mainSliderInit() {
    const $mainSlider = $('.top-block__slider').length ? $('.top-block__slider') : false

    if($mainSlider) {
        $mainSlider.slick({
            autoplay: true,
            autoplaySpeed: 5000,
            infinite: false,
            pauseOnHover: true,
            pauseOnFocus: true,
            arrows: false,
            dots: true,
            dotsClass: 'top-block__dots-box'
        })
    }
}

function detectmob() {
    if (navigator.userAgent.match(/Android/i)
        || navigator.userAgent.match(/webOS/i)
        || navigator.userAgent.match(/iPhone/i)
        || navigator.userAgent.match(/iPad/i)
        || navigator.userAgent.match(/iPod/i)
        || navigator.userAgent.match(/BlackBerry/i)
        || navigator.userAgent.match(/Windows Phone/i)
    ) {
        return true;
    }
    else {
        return false;
    }
}

$(window).on('load', function () {

    const $dataPicker = $('[data-datepicker]').length ? $('[data-datepicker]') : false

    if($dataPicker) {
        $dataPicker.datepicker({
            minDate: new Date(),
            dateFormat: 'd MM',
            language: {
                months: ['января', 'февраля', 'марта', 'апреля', 'мая', 'июня', 'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'],
            }
        });
    }


    const $brokerageSlider = $('.brokerageSlider').length ? $('.brokerageSlider') : false;

    if($brokerageSlider) {
        $brokerageSlider.on('init', function(event, slick){
            $('.pagination-slider__of').html(slick.slideCount)
        });

        $brokerageSlider.slick({
            centerMode: true,
            centerPadding: '24vw',
            slidesToShow: 1,
            variableWidth: true,
            appendArrows: '.navigation-slider',
            accessibility: true,
            prevArrow: '<button type="button" class="slick-prev"></button>',
            nextArrow: '<button type="button" class="slick-next"></button>',
            responsive: [
                {
                  breakpoint: 1280,
                  settings: {
                    slidesToShow: 1,
                    centerMode: true,
                    centerPadding: '0px',
                    variableWidth: false,
                    accessibility: false,
                    dots: false
                  }
                }
              ]
        });

        $brokerageSlider.on('afterChange', function(event, slick, currentSlide){
            $('.pagination-slider__first').html(currentSlide + 1)
        });
    }

    if (detectmob()) {
        const $menuContainer = $('[data-menu-container]').length ? $('[data-menu-container]') : false;

        if($menuContainer) {
            $menuContainer.find('a[href^="#"]').on('click', function (e) {
                e.preventDefault();
                return false;
            });
        }
    }


    let timeOut = undefined;

    const $yachtItem = $('[data-yacht-item]').length ? $('[data-yacht-item]') : false;
    if($yachtItem) {
        $yachtItem.hover(function (e) {
            if (timeOut) {
                clearTimeout(timeOut);
            }
            const $this = $(this);
            timeOut = setTimeout(function () {
                    const price = $this.data('price');
                    const item = $this.data('yacht-item');
                    const $image = $('[data-yacht-image-holder="' + item + '"]');
                    const $all = $this.closest('.yachts-block').first().find('[data-yacht-image-holder]');
                    const $priceContainer = $this.closest('.yacht-item-wrapper').find('[data-price-item]');
                    const $allItems = $this.closest('.yacht-list-inner').find('[data-yacht-item]')

                    $allItems.removeClass('active');
                    $all.removeClass('active');
                    $image.addClass('active');
                    $this.addClass('active');
                    if (price) {
                        $priceContainer.html(price);
                        $priceContainer.addClass('active');
                    } else {
                        $priceContainer.removeClass('active');
                    }
                }, 100
            );
        });
    }

    $(window).scroll(function () {
        triggerShowHeader();
    });

    function triggerShowHeader() {
        var $sticky_header = $("#sticky_header").length ? $("#sticky_header") : false,
            $containerToShow = $('#how-we-works').length ? $('#how-we-works') : false,
            marginTop = $containerToShow.length ? $containerToShow.offset().top : 400,
            margin = marginTop - $sticky_header.height();

        if ($sticky_header) {
            if ($(window).scrollTop() >= margin) {
                $sticky_header.addClass('sticky');
            } else {
                $sticky_header.removeClass('sticky');
            }
        }

    }

    const slider = $('.mobile-slider-yacht').length ? $('.mobile-slider-yacht') : false

    if(slider) {
        slider.slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            mobileFirst: true,
            arrows: false,
            dots: true,
            responsive: [
                {
                    breakpoint: 850,
                    settings: 'unslick'
                }
            ],

        }).init(function () {
            $('.fancybox').fancybox();
        });
        slider.on('beforeChange', function(event, slick, currentSlide, nextSlide){
            const next = slick.$slides.get(nextSlide);
            const price = $(next).find('[data-price]').data('price');
            const $yacht = $(next).closest('.yacht-item');
            const $price = $yacht.find('[data-price-item]');
            if($price.length){
                $price.html(price);
            }
        });
    }


    const $exibitionsSlider = $('.exibitions-block-slider').length ? $('.exibitions-block-slider') : false

    if($exibitionsSlider) {
        $exibitionsSlider.slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            dots: true,
            adaptiveHeight: true
        });
    }

    const $dataShortSlider = $('[data-short-exb-slider]').length ? $('[data-short-exb-slider]') : false

    if($dataShortSlider) {
        $dataShortSlider.slick({
            slidesToShow: 3,
            slidesToScroll: 3,
            arrows: false,
            dots: true,
            adaptiveHeight: true
        });
    }

    const $readMoreText = $('[data-read-more-text]').length ? $('[data-read-more-text]') : false

    if($readMoreText) {
        $readMoreText.readmore({
            lessLink: '<a rel="nofollow" class="button transparent">Свернуть</a>',
            moreLink: '<a rel="nofollow" class="button transparent">Читать полностью</a>',
            beforeToggle: function (trigger, element, closed) {
                element.toggleClass('not-shadow')
            },
            embedCSS: false,
        });
    }


    const $dataCustomSelect = $('[data-custom-select]').length ? $('[data-custom-select]') : false

    if($dataCustomSelect) {
        $dataCustomSelect.select2({
            templateResult: format
        });

        function format(state) {
            const originalOption = state.element;
            const image = $(originalOption).data('icon');
            const price = $(originalOption).data('price') ? $(originalOption).data('price') : '';
            const isEur = $(originalOption).data('is-eur') === 1;
            let priceString = '';
            if (!state.id) return state.text; // optgroup
            if (price) {
                priceString = !isEur
                    ? '<span class="leasing__price"><span class="icon-rub">от ' + price + '</span> <sup>с НДС 20%</sup></span> '
                    : '<span class="leasing__price">от ' + price + '</span>';
            }
            const $state = $(
                '<span class="select-2-result"><img src="' + image + '" class="img" /><div class="select-2-wrap"><span> ' + state.text + '</span>'+ priceString +'</span>'
            );
            return $state;
        }
    }
});


