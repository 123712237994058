import Polipop from 'polipop';
$(() => {
// https://github.com/minitek/polipop
    window.polipop = new Polipop('mypolipop', {
        layout: 'popups',
        insert: 'before',
        pool: 0,
        sticky: false,
        position: 'center',
        effect: 'fade',
        closer: false,
        life: 4000,
    });


    $(document).on('click', '[data-polipop]', function polipopLink(e) {
        e.preventDefault();

        polipop.add({
            content: this.dataset.polipop,
            type: 'info',
        });

    });
});