$(async function (){
    const initSlider = (container, min, max, start, end) => {
        noUiSlider.create(container, {
            start: [Number(start), Number(end)],
            connect: true,
            step: 1,
            range: {
                'min': Number(min),
                'max': Number(max)
            },
            format: wNumb({
                thousand: '.',
            })
        });
    };

    const sliderInit = (index, item, selector) => {
        const $item = $(item);
        const slider = $item.find(selector).get(0);
        const $slider = $item.find(selector);
        const dataPredefine = $slider.data('slider-predefine');
        const $min = $slider.closest('.section-slider').find('[data-min-value]');
        const $max = $slider.closest('.section-slider').find('[data-max-value]');
        initSlider(slider, $min.val(), $max.val(), $min.val(), dataPredefine);
        slider.noUiSlider.on('update', (values, handle)=>{
            const value = values[handle];
            handle ? $max.val(value) : $min.val(value);
            calc(item);
        });
        $max.on('change', calc(item));
    };
    const base = `${location.protocol}//${location.host}`;
    const calc = (item) => {
        const $priceOnce = $(item).find('[data-price-ones]');
        const $priceMonth = $(item).find('[data-price-month]');
        const $link = $(item).find('a[data-modal]');
        let url = new URL($link.attr('href'), base);
        const currentMonth = $(item).find('[data-current-month]').val();
        const currentPercentage = $(item).find('[data-current-percentage]').val();
        const total = $(item).data('total');
        const once = Math.round((total/100)*currentPercentage);console.log(once);
        const month = Math.round((total - once) / currentMonth);
        $priceOnce.html(new Intl.NumberFormat('de-DE').format(once));
        $priceMonth.html(new Intl.NumberFormat('de-DE').format(month));
        url.searchParams.set('once', once);
        $link.attr('href', url);
    };

    const monthSlider = (index, item) => {
        sliderInit(index, item, '[data-month-slider]');
    }
    const priceSlider = (index, item) => {
        sliderInit(index, item, '[data-price-percent-slider]');
    };
    $.each($('[data-yacht-block]'), (index, item) => {
        priceSlider(index, item);
        monthSlider(index, item);
    });
});
